import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'
import './layout.css'
import PageTransition from 'gatsby-plugin-page-transitions';
import classNames from 'classnames';

import nin9 from '../icons/nin9logosquaredrk.png'

const Layout = ({children}) => {

  //cursor
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [clicked, setClicked] = useState(false);
  const [linkHovered, setLinkHovered] = React.useState(false);
  const [hidden, setHidden] = useState(false);

  const addEventListeners = React.useCallback(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
  }, []);

  const removeEventListeners = React.useCallback(() => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseenter", onMouseEnter);
    document.removeEventListener("mouseleave", onMouseLeave);
    document.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mouseup", onMouseUp);
  }, []);

  useEffect(() => {
    addEventListeners();
    handleLinkHoverEvents();
    return () => removeEventListeners();
  }, [addEventListeners, removeEventListeners]);

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const onMouseDown = () => {
    setClicked(true);
  };

  const onMouseUp = () => {
    setClicked(false);
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  };

  const handleLinkHoverEvents = () => {
    document.querySelectorAll("a").forEach((el) => {
      el.addEventListener("mouseover", () => setLinkHovered(true));
      el.addEventListener("mouseout", () => setLinkHovered(false));
    });
    
    document.querySelectorAll("img").forEach((el) => {
      el.addEventListener("mouseover", () => setLinkHovered(true));
      el.addEventListener("mouseout", () => setLinkHovered(false));
    });

    document.querySelectorAll("h2").forEach((el) => {
      el.addEventListener("mouseover", () => setLinkHovered(true));
      el.addEventListener("mouseout", () => setLinkHovered(false));
    });

    document.querySelectorAll("h5 b").forEach((el) => {
      el.addEventListener("mouseover", () => setLinkHovered(true));
      el.addEventListener("mouseout", () => setLinkHovered(false));
    });
  };

  const cursorClasses = classNames("cursor", {
    "cursor--clicked": clicked,
    "cursor--hidden": hidden,
    "cursor--link-hovered": linkHovered
  });

    return (
      <>

        <nav>
            <ul>
                <li><Link fade to='/'>h<b>e</b>llo</Link></li>
                <li><Link fade to='/art'>ar<b>t</b></Link></li>
                <li><Link fade to='/code'>c<b>o</b>de</Link></li>
            </ul>
        </nav>
        
        <div
          className={cursorClasses}
          style={{ left: `${position.x}px`, top: `${position.y}px` }}/>

        <PageTransition transitionTime={500}>

            <main>
                {children}
            </main>
            <footer>
              
            </footer>
        </PageTransition>


        <mobileNav>
          <div id="mobileNavMain">
          </div>
          <Link fade to='/'><button id="prevBtn">
            <i className="fas fa-chevron-left"></i>
          </button></Link>
          <Link fade to='/code'><button id="nextBtn">
            <i className="fas fa-chevron-right"></i>
          </button></Link>
          
        </mobileNav>

        <Link 
          to="/artModal"
          state={{
            modal: true
          }}>
            <img src={nin9} alt="nin9" className="nin9Logo"/>
        </Link>
        
        
      </>
    )
}

export default Layout;